import { useState, useEffect } from 'react';

export const useWindowHeight = () => {
  const getHeight = () => window.innerHeight;

  const [windowHeight, setWindowHeight] = useState(getHeight);

  useEffect(() => {
    const handleHeight = () => {
      setWindowHeight(getHeight());
    };

    window.addEventListener('resize', handleHeight);
    return () => window.removeEventListener('resize', handleHeight);
  }, []);

  return windowHeight;
};

export const useScrollHeight = () => {
  const [scrollHeight, setScrollHeight] = useState(null);
  return {
    scrollHeight,
    setScrollHeight,
  };
};
