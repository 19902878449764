import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TelegramIcon from '@material-ui/icons/Telegram';

import mongoPartner from '../../assets/images/mongo-partner.png';
import bgPattern from '../../assets/images/pattern-dot-darker-grey.png';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: 260,
    backgroundImage: `url(${bgPattern})`,
    backgroundColor: '#424242',
  },
  img: {
    margin: '35px 0',
    padding: '0 30px',
    maxHeight: '115px',
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
  },
  text: {
    color: '#dcddd8',
    fontSize: '14px',
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Grid container direction="column" justify="center" alignItems="center" className={classes.root}>
      <Grid item>
        <img src={mongoPartner} className={classes.img} alt="Mongo partner" />
      </Grid>
      <Grid item>
        <Grid item container spacing={1} wrap="nowrap">
          <Grid item>
            <Typography noWrap className={classes.text}>
              <FormattedHTMLMessage id="footer.copyright" />
            </Typography>
          </Grid>
          <Grid item>
            <TelegramIcon fontSize="large" />
          </Grid>
          <Grid item>
            <Typography noWrap className={classes.text}>
              <FormattedMessage id="footer.rights" />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Footer;
