import React from 'react';
import { map, equals } from 'ramda';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import TelegramIcon from '@material-ui/icons/Telegram';
import MenuIcon from '@material-ui/icons/Menu';
import MailIcon from '@material-ui/icons/Mail';
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import WorkIcon from '@material-ui/icons/Work';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { useDrawer } from './hooks';

const useStyles = makeStyles(theme => ({
  selected: {
    textDecoration: 'none',
    WebkitTextFillColor: '#CD4436',
    '& svg': {
      color: '#CD4436',
    },
  },
  title: {
    fontSize: '1.8rem',
    color: '#dcddd8',
  },
  Icon: {
    fontSize: '2rem',
    display: 'inline',
    color: '#dcddd8',
  },
  homeBtn: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const entries = [
  {
    id: 1,
    name: 'home',
    icon: HomeIcon,
    path: '/home',
  },
  {
    id: 2,
    name: 'technologies',
    icon: SettingsIcon,
    path: '/technologies',
  },
  {
    id: 3,
    name: 'portfolio',
    icon: WorkIcon,
    path: '/portfolio',
  },
];

const Header = ({ breadcrumbs }) => {
  const classes = useStyles();
  const { isDrawerVisible, toggleDrawer } = useDrawer();

  return (
    <Box>
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Grid container justify="space-between" alignItems="center" wrap="nowrap">
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <IconButton aria-label="pages list" edge="start" color="inherit" onClick={toggleDrawer}>
                    <MenuIcon className={classes.Icon} />
                  </IconButton>
                </Grid>

                <Hidden xsDown>
                  <Grid item>
                    <Typography className={classes.title}>{breadcrumbs}</Typography>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
            <Grid item>
              <Fab
                component={Link}
                aria-label="home"
                variant="extended"
                size="medium"
                color="primary"
                to="/home"
                className={classes.homeBtn}
              >
                <TelegramIcon viewBox="3 2 19 19" className={classes.Icon} />
                &nbsp;
                <Typography className={classes.title}>
                  <FormattedMessage id="redpelicans" />
                </Typography>
              </Fab>
            </Grid>
            <Grid item>
              <IconButton aria-label="contact us" color="inherit" href="mailto:contact@redpelicans.com">
                <MailIcon className={classes.Icon} />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar variant="dense" />
      <SwipeableDrawer
        anchor="left"
        open={isDrawerVisible}
        onOpen={toggleDrawer}
        onClose={toggleDrawer}
        transitionDuration={300}
      >
        <List>
          <ListItem component={Link} to="#" onClick={toggleDrawer} divider>
            <ListItemIcon>
              <CloseIcon className={classes.Icon} />
            </ListItemIcon>
          </ListItem>
          {map(
            entry => (
              <ListItem
                key={entry.id}
                component={Link}
                to={entry.path}
                onClick={toggleDrawer}
                selected={equals(breadcrumbs, entry.name)}
                classes={{ selected: classes.selected }}
              >
                <ListItemIcon>
                  <Icon component={entry.icon} className={classes.Icon} />
                </ListItemIcon>
                <ListItemText
                  primary={<FormattedMessage id={`menu.${entry.name}`} />}
                  disableTypography={false}
                  primaryTypographyProps={{ variant: 'h5' }}
                  inset
                />
              </ListItem>
            ),
            entries,
          )}
        </List>
      </SwipeableDrawer>
    </Box>
  );
};

export default Header;
