import React from 'react';
import { map } from 'ramda';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import dockerThumb from '../../assets/images/docker-thumb.png';
import mongodbThumb from '../../assets/images/mongodb-thumb.png';
import nodejsThumb from '../../assets/images/nodejs-thumb.png';
import reactThumb from '../../assets/images/react-thumb.png';
import d3Thumb from '../../assets/images/d3-thumb.png';
import redisThumb from '../../assets/images/redis-thumb.png';
import kubeThumb from '../../assets/images/kubernetes.png';
import solrThumb from '../../assets/images/solr.png';

import bgPattern from '../../assets/images/pattern-dot-light-grey.png';
import Hexagon from '../hexagon';

const useStyles = makeStyles({
  root: {
    backgroundImage: `url(${bgPattern})`,
    backgroundColor: '#f4f5f3',
    padding: '6rem 0rem',
  },
  container: {
    maxWidth: '97rem',
    padding: '0rem 1.5rem 2rem',
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: '2rem',
    color: '#cd4436',
    paddingBottom: '6rem',
  },
  item: {
    padding: '2rem 1.5rem',
    '&:hover': {
      '& p': {
        padding: '2rem 1.3rem',
        borderBottom: '2px solid #cd4436',
      },
    },
  },
  image: {
    maxHeight: '100px',
    maxWidth: '120px',
    marginBottom: '20px',
    transition: 'all .6s ease-in 0s',
    '&:hover': {
      transform: 'translateY(10px)',
    },
  },
  imageTitle: {
    color: '#cd4436',
    fontSize: '2rem',
    padding: '2rem 0rem 2.5rem',
  },
  anchor: {
    textDecoration: 'none',
  },
  hexagon: {
    margin: '2rem 0rem -9.5rem',
  },
});

const items = [
  { name: 'Node.js', image: nodejsThumb, link: 'https://nodejs.org/' },
  {
    name: 'React',
    image: reactThumb,
    link: 'http://facebook.github.io/react/',
  },
  { name: 'D3JS', image: d3Thumb, link: 'https://d3js.org/' },
  { name: 'MongoDB', image: mongodbThumb, link: 'https://www.mongodb.org/' },
  { name: 'Kubernetes', image: kubeThumb, link: 'https://kubernetes.io/' },
  { name: 'Docker', image: dockerThumb, link: 'https://www.docker.com/' },
  { name: 'Solr', image: solrThumb, link: 'https://lucene.apache.org/solr/' },
  { name: 'Redis', image: redisThumb, link: 'https://redis.io/' },
];

const Stack = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.title}>
              <FormattedMessage id="home.stack.h" />
            </Typography>
          </Grid>
          {map(
            item => (
              <Grid item xs={12} sm={3} key={item.name} className={classes.item}>
                <a href={item.link} className={classes.anchor} target="_blank" rel="noopener noreferrer">
                  <img src={item.image} alt={item.name} className={classes.image} />
                  <Typography className={classes.imageTitle}>{item.name}</Typography>
                </a>
              </Grid>
            ),
            items,
          )}
        </Grid>
        <Grid item className={classes.hexagon}>
          <Hexagon hexaColor="#dcddd8" sideLength="7" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Stack;
