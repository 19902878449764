import React from 'react';
import { render, hydrate } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import CssBaseline from '@material-ui/core/CssBaseline';
import { create } from 'jss';
import { StylesProvider, createGenerateClassName, jssPreset } from '@material-ui/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import localeData from '../i18n/data.json';
import App from './components/app';
import appTheme from './theme';
import * as serviceWorker from './serviceWorker';

const language = 'en';
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
const messages = localeData[languageWithoutRegionCode] || localeData[language] || localeData.en;

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  insertionPoint: 'jss-insertion-point',
});
const theme = createMuiTheme(appTheme);

const mountNode = window.document.getElementById('root');
const root = (
  <StylesProvider jss={jss} generateClassName={generateClassName}>
    <IntlProvider locale={language} messages={messages}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <App ssr />
        </Router>
      </MuiThemeProvider>
    </IntlProvider>
  </StylesProvider>
);

// render(root, mountNode, () => {
//   const jssStyles = document.getElementById('jss-server-side');
//   if (jssStyles && jssStyles.parentNode) {
//     jssStyles.parentNode.removeChild(jssStyles);
//   }
// });

if (mountNode.hasChildNodes()) {
  hydrate(root, mountNode);
} else {
  render(root, mountNode);
}
serviceWorker.register();
