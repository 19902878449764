import React from 'react';
import { map } from 'ramda';
import { FormattedHTMLMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import PublicIcon from '@material-ui/icons/Public';
import FavoriteIcon from '@material-ui/icons/Favorite';

import ecosystem from '../../assets/images/ecosystem.png';
import performance from '../../assets/images/performance.png';
import adoption from '../../assets/images/adoption.png';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '6rem 0rem',
  },
  container: {
    maxWidth: '97rem',
    padding: '0rem 1.5rem 2rem',
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: '4rem',
    color: '#cd4436',
    marginBottom: '2rem',
    padding: '0rem 1.5rem',
  },
  paragraph: {
    fontSize: '1.6rem',
    color: '#333',
    padding: '0rem 1.5rem 6rem',
    '& q': {
      color: '#cd4436',
    },
  },
  card: {
    padding: '0rem 1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0rem',
    },
  },
  icon: {
    fontSize: '16rem',
    color: '#cd4436',
    padding: '0rem 1.5rem',
  },
  techTitle: {
    fontSize: '2.2rem',
    color: '#cd4436',
    padding: '2rem 0rem 2.5rem',
  },
  techParagraph: {
    fontSize: '1.4rem',
    color: '#424242',
    margin: '0rem 1rem 4rem',
  },
  techLink: {
    fontSize: '1.4rem',
    color: '#cd4436',
    '& a': {
      color: '#cd4436',
      textDecoration: 'none',
      '&:hover': {
        color: '#cd4436',
        textDecoration: 'underline',
      },
    },
  },
  img: {
    height: 'auto',
    width: '100%',
    maxWidth: '28rem',
    backgroundSize: 'auto',
    marginTop: '4rem',
  },
  lastParagraph: {
    fontSize: '1.6rem',
    color: '#333',
    padding: '6rem 1.5rem 0rem',
    '& q': {
      color: '#cd4436',
    },
  },
}));

const JSTECH = [
  {
    id: 1,
    icon: SignalCellularAltIcon,
    image: ecosystem,
  },
  {
    id: 2,
    icon: PublicIcon,
    image: performance,
  },
  {
    id: 3,
    icon: FavoriteIcon,
    image: adoption,
  },
];

const Javascript1 = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.container}>
        <Typography className={classes.title}>
          <FormattedHTMLMessage id="technologies.javascript1.h1" />
        </Typography>
        <Typography className={classes.paragraph}>
          <FormattedHTMLMessage id="technologies.javascript1.p1" />
        </Typography>
        <Grid container justify="center" alignItems="flex-start">
          {map(
            jstech => (
              <Grid item container key={jstech.id} className={classes.card} xs={12} md={4}>
                <Grid item xs={12}>
                  <Icon component={jstech.icon} className={classes.icon} />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.techTitle}>
                    <FormattedHTMLMessage id={`technologies.javascript1.content.h${jstech.id}`} />
                  </Typography>

                  <Typography className={classes.techParagraph}>
                    <FormattedHTMLMessage id={`technologies.javascript1.content.p${jstech.id}`} />
                  </Typography>

                  <Typography className={classes.techLink}>
                    <FormattedHTMLMessage id={`technologies.javascript1.content.s${jstech.id}`} />
                  </Typography>

                  <img src={jstech.image} alt={jstech.icon} className={classes.img} />
                </Grid>
              </Grid>
            ),
            JSTECH,
          )}
        </Grid>
        <Typography className={classes.lastParagraph}>
          <FormattedHTMLMessage id="technologies.javascript1.h2" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Javascript1;
