import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TelegramIcon from '@material-ui/icons/Telegram';
import SpeedIcon from '@material-ui/icons/Speed';
import BarChartIcon from '@material-ui/icons/BarChart';
import EcoIcon from '@material-ui/icons/Eco';
import DnsIcon from '@material-ui/icons/Dns';
import Hidden from '@material-ui/core/Hidden';

import Hexagon from '../hexagon';
import bgPattern from '../../assets/images/pattern-dot-light-grey.png';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${bgPattern})`,
    backgroundColor: '#fff',
    padding: '6rem 0rem',
    color: '#424242',
    '& a': {
      color: '#424242',
      borderBottom: '1px dotted #424242',
      textDecoration: 'none',
      '&:hover': {
        color: '#cd4436',
        borderBottom: '1px dotted #cd4436',
      },
    },
  },
  container: {
    position: 'relative',
    maxWidth: '97rem',
    padding: '0rem 1.5rem 2rem',
    margin: 'auto',
    textAlign: 'center',
  },
  textHexagon: {
    position: 'absolute',
    fontSize: '1.4rem',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    width: '100%',
    padding: '1rem',
  },
  lgHexagon: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    width: '100%',
    fontSize: '1.4rem',
    color: '#dcddd8',
    padding: '0rem 1rem 1rem',
    '& svg': {
      color: '#dcddd8',
      fontSize: '8rem',
      display: 'inline',
    },
    '& p': {
      '& span': {
        fontSize: '1.6rem',
        color: '#dcddd8',
        textTransform: 'uppercase',
      },
    },
    '& a': {
      textDecoration: 'none',
      borderBottom: '1px dotted #dcddd8',
      '&:hover': {
        borderBottom: '1px dotted #fff',
        '& span': {
          color: '#fff',
        },
      },
    },
    '& span': {
      color: '#dcddd8',
      fontSize: '1.4rem',
    },
  },
  redHexagon: {
    color: '#cd4436',
    '& a': {
      color: '#cd4436',
      borderBottom: '1px dotted #cd4436',
      textDecoration: 'none',
      '&:hover': {
        color: '#424242',
        borderBottom: '1px dotted #424242',
      },
    },
  },
  centredRow: {
    [theme.breakpoints.up('970')]: {
      margin: '-5.85rem 0rem',
    },
    [theme.breakpoints.down('970')]: {
      margin: '-7.85rem 0rem',
    },
    [theme.breakpoints.down('659')]: {
      margin: '0rem 0rem',
    },
  },
}));

const linkFactory = (anchor, intlKey) => (
  <Link to={`/technologies#${anchor}`}>
    <FormattedHTMLMessage id={intlKey} />
  </Link>
);

const Content = () => {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('970'));
  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Grid container justify="center">
          <Grid item>
            <Hidden xsUp={md}>
              <Hexagon strokeColor="#424242" opacity="0">
                <Box className={classes.textHexagon}>
                  <FormattedMessage
                    id="home.frontend.p"
                    values={{
                      angularjs: linkFactory('angularjs', 'home.link.angularjs'),
                      react: linkFactory('react', 'home.link.react'),
                    }}
                  />
                </Box>
              </Hexagon>
            </Hidden>
          </Grid>

          <Grid item>
            <Hexagon sideLength={md ? '150' : '110'} hexaColor="#424242">
              <Box className={classes.lgHexagon}>
                <SpeedIcon />

                <Typography display="block">{linkFactory('frontend', 'home.frontend.h')}</Typography>
                <Hidden xsUp={!md}>
                  <FormattedMessage
                    id="home.frontend.p"
                    values={{
                      angularjs: linkFactory('angularjs', 'home.link.angularjs'),
                      react: linkFactory('react', 'home.link.react'),
                    }}
                  />
                </Hidden>
              </Box>
            </Hexagon>
          </Grid>

          <Grid item>
            <Hexagon sideLength={md ? '150' : '110'} hexaColor="#424242">
              <Box className={classes.lgHexagon}>
                <BarChartIcon />
                <Typography>{linkFactory('datavisualization', 'home.datavisualization.h')}</Typography>
                <Hidden xsUp={!md}>
                  <FormattedHTMLMessage id="home.datavisualization.p1" />
                  <FormattedMessage
                    id="home.datavisualization.p2"
                    values={{
                      d3: linkFactory('d3', 'home.link.d3'),
                    }}
                  />
                </Hidden>
              </Box>
            </Hexagon>
          </Grid>

          <Grid item>
            <Hidden xsUp={md}>
              <Hexagon strokeColor="#424242" opacity="0">
                <Box className={classes.textHexagon}>
                  <FormattedHTMLMessage id="home.datavisualization.p1" />
                  <FormattedMessage
                    id="home.datavisualization.p2"
                    values={{
                      d3: linkFactory('d3', 'home.link.d3'),
                    }}
                  />
                </Box>
              </Hexagon>
            </Hidden>
          </Grid>
        </Grid>

        <Grid container justify="center" className={classes.centredRow}>
          <Grid item>
            <Hidden xsUp={md}>
              <Hexagon opacity="0">
                <Box className={`${classes.textHexagon} ${classes.redHexagon}`}>
                  <FormattedMessage
                    id="home.consulting.p1"
                    values={{
                      fullstackjs: linkFactory('full-stack-javascript', 'home.link.fullstackjs'),
                    }}
                  />
                </Box>
              </Hexagon>
            </Hidden>
          </Grid>

          <Grid item>
            <Hexagon sideLength={md ? '150' : '110'} strokeColor="#bb3c2f">
              <Box className={classes.lgHexagon}>
                <TelegramIcon viewBox="3 3 19 19" />
                <Typography>
                  <FormattedHTMLMessage id="home.consulting.h" />
                </Typography>
                <Hidden xsUp={!md}>
                  <FormattedMessage
                    id="home.consulting.p1"
                    values={{
                      fullstackjs: linkFactory('full-stack-javascript', 'home.link.fullstackjs'),
                    }}
                  />
                </Hidden>
              </Box>
            </Hexagon>
          </Grid>

          <Grid item>
            <Hidden xsUp={md}>
              <Hexagon opacity="0">
                <Box className={`${classes.textHexagon} ${classes.redHexagon}`}>
                  <FormattedHTMLMessage id="home.consulting.p2" />
                </Box>
              </Hexagon>
            </Hidden>
          </Grid>
        </Grid>

        <Grid container justify="center">
          <Grid item>
            {' '}
            <Hidden xsUp={md}>
              <Hexagon strokeColor="#424242" opacity="0">
                <Box className={classes.textHexagon}>
                  <FormattedMessage
                    id="home.backend.p"
                    values={{
                      nodejs: linkFactory('nodejs', 'home.link.nodejs'),
                    }}
                  />
                </Box>
              </Hexagon>
            </Hidden>
          </Grid>

          <Grid item>
            <Hexagon sideLength={md ? '150' : '110'} hexaColor="#424242">
              <Box className={classes.lgHexagon}>
                <DnsIcon />
                <Typography>{linkFactory('backend', 'home.backend.h')}</Typography>
                <Hidden xsUp={!md}>
                  <FormattedMessage
                    id="home.backend.p"
                    values={{
                      nodejs: linkFactory('nodejs', 'home.link.nodejs'),
                    }}
                  />
                </Hidden>
              </Box>
            </Hexagon>
          </Grid>

          <Grid item>
            <Hexagon sideLength={md ? '150' : '110'} hexaColor="#424242">
              <Box className={classes.lgHexagon}>
                <EcoIcon />
                <Typography>{linkFactory('nosql', 'home.nosql.h')}</Typography>
                <Hidden xsUp={!md}>
                  <FormattedMessage
                    id="home.nosql.p"
                    values={{
                      mongodb: linkFactory('mongodb', 'home.link.mongodb'),
                    }}
                  />
                </Hidden>
              </Box>
            </Hexagon>
          </Grid>

          <Grid item>
            <Hidden xsUp={md}>
              <Hexagon strokeColor="#424242" opacity="0">
                <Box className={classes.textHexagon}>
                  <FormattedMessage
                    id="home.nosql.p"
                    values={{
                      mongodb: linkFactory('mongodb', 'home.link.mongodb'),
                    }}
                  />
                </Box>
              </Hexagon>
            </Hidden>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Content;
