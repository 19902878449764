import React from 'react';
import { map } from 'ramda';
import { FormattedHTMLMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import d3 from '../../assets/images/d3js.png';
import bgPattern from '../../assets/images/pattern-dot-grey.png';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${bgPattern})`,
    backgroundColor: '#f4f5f3',
    padding: '6rem 0rem',
  },
  container: {
    maxWidth: '97rem',
    padding: '0rem 1.5rem 2rem',
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: '4rem',
    color: '#cd4436',
    marginBottom: '2rem',
    padding: '0rem 1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0rem',
    },
  },
  paragraph: {
    fontSize: '1.6rem',
    color: '#333',
    padding: '0rem 1.5rem 6rem',
    '& q': {
      color: '#cd4436',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0rem 0rem 6rem',
    },
  },
  img: {
    height: 'auto',
    width: '100%',
    maxWidth: '50rem',
    backgroundSize: 'auto',
    padding: '0rem 2rem 4rem',
  },
  card: {
    padding: '0rem 1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0rem',
    },
  },
  techTitle: {
    fontSize: '1.6rem',
    fontWeight: '700',
    color: '#bb3c2f',
    padding: '2rem 0rem 2.5rem',
  },
  techParagraph: {
    fontSize: '1.4rem',
    color: '#424242',
    margin: '0rem 1rem 4rem',
    [theme.breakpoints.down('xs')]: {
      margin: '0rem 0rem 4rem',
    },
  },
  lastParagraph: {
    fontSize: '1.6rem',
    color: '#333',
    padding: '6rem 1.5rem 0rem',
    '& q': {
      color: '#cd4436',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '6rem 0rem 0rem',
    },
  },
}));

const Datavisualization = () => {
  const classes = useStyles();
  return (
    <Grid id="datavisualization" container className={classes.root}>
      <Grid item className={classes.container}>
        <Typography className={classes.title}>
          <FormattedHTMLMessage id="technologies.datavisualization.h" />
        </Typography>

        <Typography className={classes.paragraph}>
          <FormattedHTMLMessage id="technologies.datavisualization.content.p1" />
        </Typography>

        <img id="d3" src={d3} alt="d3" className={classes.img} />

        <Grid container justify="center">
          {map(
            data => (
              <Grid key={data} item container justify="center" xs={12} sm={4} className={classes.card}>
                <Grid item>
                  <Typography className={classes.techTitle}>
                    <FormattedHTMLMessage id={`technologies.datavisualization.content.h${data}`} />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.techParagraph}>
                    <FormattedHTMLMessage id={`technologies.datavisualization.content.p${data}`} />
                  </Typography>
                </Grid>
              </Grid>
            ),
            [2, 3, 4],
          )}
        </Grid>

        <Typography className={classes.lastParagraph}>
          <FormattedHTMLMessage id="technologies.datavisualization.content.p5" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Datavisualization;
