import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import MenuBookIcon from '@material-ui/icons/MenuBook';

import bgPattern from '../../assets/images/pattern-dot-light-grey.png';

const useStyles = makeStyles({
  root: {
    backgroundImage: `url(${bgPattern})`,
    backgroundColor: '#fff',
    padding: '6rem 0rem',
  },
  container: {
    maxWidth: '97rem',
    padding: '0rem 1.5rem 2rem',
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: '2rem',
    color: '#cd4436',
    paddingBottom: '6rem',
    '& q': {
      color: '#424242',
    },
  },
  icon: {
    color: '#cd4436',
    fontSize: '14rem',
    display: 'inline',
  },
  iconTitle: {
    color: '#cd4436',
    fontSize: '2.2rem',
    padding: '2rem 0rem 2.5rem',
  },
  iconParagraph: {
    fontSize: '1.4rem',
    marginBottom: '4rem',
  },
});

export const School = () => {
  const classes = useStyles();
  return (
    <Box id="school" className={classes.root}>
      <Grid container direction="column" className={classes.container}>
        <Grid item>
          <Typography className={classes.title}>
            <FormattedHTMLMessage id="home.school.h" />
          </Typography>
        </Grid>
        <Grid item>
          <Icon component={MenuBookIcon} className={classes.icon} />
        </Grid>

        <Grid item>
          <Typography className={classes.iconTitle}>
            <FormattedMessage id="home.school.content.h" />
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.iconParagraph}>
            <FormattedHTMLMessage id="home.school.content.p" />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default School;
