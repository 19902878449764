import { useState } from 'react';

export const useBreadcrumbs = () => {
  const [breadcrumbs, setBreadcrumbs] = useState('');

  return {
    breadcrumbs,
    setBreadcrumbs,
  };
};

export const useSsr = ssr => {
  const [_ssr, setSsr] = useState(ssr);

  return {
    _ssr,
    setSsr,
  };
};
