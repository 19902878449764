import React from 'react';
import { map } from 'ramda';
import { FormattedMessage } from 'react-intl';
import Scroll from 'react-scroll';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import SpeedIcon from '@material-ui/icons/Speed';
import BarChartIcon from '@material-ui/icons/BarChart';
import EcoIcon from '@material-ui/icons/Eco';
import DnsIcon from '@material-ui/icons/Dns';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(theme => ({
  icon: {
    padding: '1.5rem',
    color: '#dcddd8',
    transition: 'all .5s ease-in 0s',
    '&:hover': {
      cursor: 'pointer',
      color: '#fff',
      transform: 'translateY(1rem)',
    },
    '& svg': {
      fontSize: '6rem',
    },
  },
  headroomIcon: {
    padding: '1rem',
    color: '#dcddd8',
    transition: 'all .5s ease-in 0s',
    '&:hover': {
      cursor: 'pointer',
      color: '#fff',
      transform: 'translateY(1rem)',
    },
    '& svg': {
      fontSize: '3rem',
    },
  },
  headroomHeadingStyle: {
    fontSize: '1rem',
    color: '#dcddd8',
    paddingBottom: '1rem',
  },
  headingStyle: {
    fontSize: '1.5rem',
    color: '#fff',
  },
  paragraphStyle: {
    fontSize: '1.2rem',
    color: '#dcddd8',
  },
  active: {
    '& svg,p': {
      color: '#fff',
    },
  },
}));

const TECHS = [
  {
    to: 'datavisualization',
    icon: BarChartIcon,
  },
  {
    to: 'frontend',
    icon: SpeedIcon,
  },
  {
    to: 'backend',
    icon: DnsIcon,
  },
  {
    to: 'nosql',
    icon: EcoIcon,
  },
];

const Nav = ({ headroom }) => {
  const classes = useStyles();

  return (
    <Grid container>
      {map(
        tech => (
          <Grid item key={tech.to} xs={headroom ? 3 : 6} sm={3}>
            <Scroll.Link
              aria-label={`scroll to ${tech.to}`}
              activeClass={classes.active}
              to={tech.to}
              offset={-126}
              spy={true}
              smooth={true}
              duration={2000}
            >
              <Box align="center" className={headroom ? `${classes.headroomIcon}` : `${classes.icon}`}>
                <Icon component={tech.icon} />
              </Box>

              <Typography align="center" className={headroom ? classes.headroomHeadingStyle : classes.headingStyle}>
                <FormattedMessage id={`technologies.${tech.to}.h`} />
              </Typography>

              <Hidden xsUp={headroom}>
                <Typography align="center" className={classes.paragraphStyle}>
                  <FormattedMessage id={`technologies.${tech.to}.p`} />
                </Typography>
              </Hidden>
            </Scroll.Link>
          </Grid>
        ),
        TECHS,
      )}
    </Grid>
  );
};

export default Nav;
