import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import BuildIcon from '@material-ui/icons/Build';
import FlagIcon from '@material-ui/icons/Flag';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

import bgPattern from '../../assets/images/pattern-dot-light-grey.png';

const useStyles = makeStyles({
  root: {
    backgroundImage: `url(${bgPattern})`,
    backgroundColor: '#fff',
    padding: '6rem 0rem',
  },
  container: {
    maxWidth: '97rem',
    padding: '0rem 1.5rem 2rem',
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: '2rem',
    color: '#cd4436',
    padding: '0rem 1.5rem 6rem',
    '& q': {
      color: '#424242',
    },
  },
  contentIcon: {
    color: '#cd4436',
    fontSize: '14rem',
    display: 'inline',
  },
  contentTitle: {
    color: '#cd4436',
    fontSize: '2.2rem',
    padding: '2rem 0rem 2.5rem',
  },
  contentParagraph: {
    fontSize: '1.4rem',
    marginBottom: '4rem',
  },
  contactusContainer: {
    alignSelf: 'center',
  },
  contactus: {
    borderRadius: '0px',
    border: '1px solid #fff',
    padding: '15px 25px',
    color: '#fff',
    backgroundColor: '#cd4436',
    fontSize: '16px',
    '&:hover, &:focus': {
      textDecoration: 'none',
      color: '#CD4436',
      backgroundColor: '#fff',
      border: '1px solid #CD4436',
    },
  },
});

const Consulting = ({ id, icon }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Grid item>
        <Icon component={icon} className={classes.contentIcon} />
      </Grid>
      <Grid item>
        <Typography className={classes.contentTitle}>
          <FormattedMessage id={`home.consulting2.content.h${id}`} />
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.contentParagraph}>
          <FormattedHTMLMessage id={`home.consulting2.content.p${id}`} />
        </Typography>
      </Grid>
    </Grid>
  );
};

const Consulting2 = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography className={classes.title}>
            <FormattedHTMLMessage id="home.consulting2.h" />
          </Typography>
        </Grid>
        <Grid item container alignContent="center" spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <Consulting id={1} icon={BuildIcon} />
          </Grid>
          <Hidden xsUp={!matches}>
            <Grid item md={4} className={classes.contactusContainer}>
              <Button
                aria-label="contact us"
                href="mailto:contact@redpelicans.com"
                classes={{ root: classes.contactus }}
              >
                <FormattedMessage id="contactus" />
              </Button>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6} md={4}>
            <Consulting id={2} icon={FlagIcon} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Consulting2;
