import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { addIndex, map } from 'ramda';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TelegramIcon from '@material-ui/icons/Telegram';
import routes, { defaultRoute } from '../../../routes';
import { transitionedPage } from '../../utils';
import Header from '../header';
import { useBreadcrumbs, useSsr } from './hooks';

const useStyles = makeStyles({
  preloader: {
    paddingTop: '4.8rem',
    textAlign: 'center',
    color: '#CD4436',
  },
  icon: {
    fontSize: '20rem',
    marginTop: '4rem',
  },
});

const makeRoute = (route, withTransition, setBreadcrumbs) => props => {
  const pageName = 'app';
  setBreadcrumbs(route.name);
  const Wrapped = withTransition ? transitionedPage(pageName)(route.component) : route.component;
  return <Wrapped {...props} name={route.name} />;
};

const mapIndex = addIndex(map);

const App = ({ ssr }) => {
  const classes = useStyles();
  const { breadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const { _ssr, setSsr } = useSsr(ssr);
  const { pathname } = useLocation();

  useEffect(() => {
    if (ssr) setSsr(false);
    window.scrollTo(0, 0);
  }, [ssr, setSsr, pathname]);

  return _ssr ? (
    <Box className={classes.preloader}>
      <TelegramIcon viewBox="3 2 19 19" className={classes.icon} />
    </Box>
  ) : (
    <Grid container justify="center" data-testid="loaded">
      <Grid item xs={12}>
        <Header breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid item xs={12}>
        <Switch>
          {mapIndex(
            (route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                render={makeRoute(route, !_ssr, setBreadcrumbs)}
              />
            ),
            routes,
          )}
          <Redirect to={defaultRoute().path} />
        </Switch>
      </Grid>
    </Grid>
  );
};

App.propTypes = {
  ssr: propTypes.bool,
};
export default App;
