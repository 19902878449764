import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Hidden from '@material-ui/core/Hidden';

import bgPattern from '../../assets/images/pattern-dot-light-grey.png';

const useStyles = makeStyles({
  root: {
    backgroundImage: `url(${bgPattern})`,
    backgroundColor: '#f4f5f3',
    padding: '6rem 0rem',
  },
  container: {
    maxWidth: '97rem',
    padding: '0rem 1.5rem 2rem',
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: '2rem',
    color: '#cd4436',
    padding: '0rem 1.5rem 6rem',
    '& q': {
      color: '#424242',
    },
  },
  iconContainer: {
    alignSelf: 'center',
  },
  icon: {
    color: '#cd4436',
    fontSize: '8rem',
    display: 'inline',
  },
  iconTitle: {
    color: '#cd4436',
    fontSize: '2.2rem',
    padding: '2rem 0rem 2.5rem',
  },
  iconParagraph: {
    fontSize: '1.4rem',
    marginBottom: '4rem',
  },
  paragraph: {
    fontSize: '2rem',
    padding: '6rem 0rem 3rem',
    color: '#cd4436',
    '& q': {
      color: '#424242',
    },
  },
});

const Devops = () => {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const sm = useMediaQuery('(max-width:750px)');
  return (
    <Box className={classes.root}>
      <Grid container justify={md ? 'center' : 'space-evenly'} className={classes.container}>
        <Grid item xs={12}>
          <Typography className={classes.title}>
            <FormattedHTMLMessage id="home.devops.h1" />
          </Typography>
        </Grid>

        <Hidden xsUp={md}>
          <Grid item xs={12}>
            <CloudUploadIcon className={classes.icon} />
          </Grid>
        </Hidden>

        <Grid item container direction="column" xs={sm ? 12 : 5}>
          <Grid item>
            <Typography className={classes.iconTitle}>
              <FormattedMessage id="home.devops.content.h1" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.iconParagraph}>
              <FormattedHTMLMessage id="home.devops.content.p1" />
            </Typography>
          </Grid>
        </Grid>
        <Hidden xsUp={!md}>
          <Grid item xs={2} className={classes.iconContainer}>
            <CloudUploadIcon className={classes.icon} />
          </Grid>
        </Hidden>
        <Grid item container direction="column" xs={sm ? 12 : 5}>
          <Grid item>
            <Typography className={classes.iconTitle}>
              <FormattedMessage id="home.devops.content.h2" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.iconParagraph}>
              <FormattedHTMLMessage id="home.devops.content.p2" />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            <FormattedHTMLMessage id="home.devops.h2" />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Devops;
