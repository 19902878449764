import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Nav from './nav';
import Hexagon from '../hexagon';
import darkRedDotPattern from '../../assets/images/pattern-dot-dark-red.png';

const useStyles = makeStyles({
  root: {
    backgroundImage: `url(${darkRedDotPattern})`,
    backgroundColor: '#CD4436',
    color: '#fff',
    paddingBottom: '1rem',
  },
  container: {
    maxWidth: '97rem',
    padding: '0rem 1.5rem 2rem',
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: '5rem',
    color: '#dcddd8',
    margin: '6rem 0rem 1rem',
  },
  paragraph: {
    fontSize: '2rem',
    color: '#fff',
    padding: '0rem 1.5rem',
    margin: '2rem 0rem 3rem',
  },
  nav: {
    width: '100%',
    margin: '4rem 0 2rem',
  },
  hexagon: {
    margin: '5rem 0rem -4.5rem',
  },
});

const Splash = ({ splashRef }) => {
  const classes = useStyles();
  return (
    <Box ref={splashRef} className={classes.root}>
      <Grid container direction="column" justify="space-between" alignItems="center" className={classes.container}>
        <Grid item>
          <Typography className={classes.title}>
            <FormattedHTMLMessage id="technologies.splash.h1" />
          </Typography>
        </Grid>
        <Grid item>
          <Hexagon hexaColor="#bb3c2f" sideLength="7" />
        </Grid>
        <Grid item>
          <Typography className={classes.paragraph}>
            <FormattedHTMLMessage id="technologies.splash.h2" />
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.paragraph}>
            <FormattedHTMLMessage id="technologies.splash.h3" />
          </Typography>
        </Grid>
        <Grid item className={classes.nav}>
          <Nav />
        </Grid>
        <Box className={classes.hexagon}>
          <Hexagon hexaColor="#dcddd8" sideLength="7" />
        </Box>
      </Grid>
    </Box>
  );
};

export default Splash;
