import React from 'react';
import { map, toLower } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles(theme => ({
  container: {
    width: '350px',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    height: '100%',
  },
  media: {
    height: '140px',
    backgroundSize: 'contain',
    padding: '0% 25%',
  },
  text: {
    textAlign: 'center',
    fontWeight: 'fontWeightRegular',
    fontSize: '1.4rem',
    color: '#333',
    '& a': {
      textDecoration: 'none',
      color: '#333',
      borderBottom: '0.1rem dotted #333',
      '&:hover': {
        borderBottom: '1px dotted #cd4436',
        color: '#cd4436',
      },
    },
  },
  cardFooter: {
    marginTop: 'auto',
    padding: '1.6rem',
  },
}));

const Client = ({ name, content, tags, link }) => {
  const classes = useStyles();
  return (
    <Card square className={classes.container}>
      <CardActionArea aria-label={name} className={classes.card} href={link} target="_blank" rel="noopener noreferrer">
        <CardMedia image={`${toLower(name)}.png`} className={classes.media} />

        <CardContent>
          <Typography color="textSecondary" dangerouslySetInnerHTML={{ __html: content }} className={classes.text} />
        </CardContent>

        <Grid container justify="center" spacing={1} className={classes.cardFooter}>
          {map(
            tag => (
              <Grid item key={tag}>
                <Chip label={tag} color="primary" size="small" />
              </Grid>
            ),
            tags,
          )}
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default Client;
