import React from 'react';
import { map } from 'ramda';
import { FormattedHTMLMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import mongodb from '../../assets/images/mongodb.png';
import bgPattern from '../../assets/images/pattern-dot-light-grey.png';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${bgPattern})`,
    backgroundColor: '#fff',
    padding: '6rem 0rem',
  },
  container: {
    maxWidth: '97rem',
    padding: '0rem 1.5rem 2rem',
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: '4rem',
    color: '#cd4436',
    marginBottom: '2rem',
    padding: '0rem 1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0rem',
    },
  },
  paragraph: {
    fontSize: '1.6rem',
    color: '#333',
    padding: '0rem 1.5rem 6rem',
    '& q': {
      color: '#cd4436',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0rem 0rem 6rem',
    },
  },
  img: {
    height: 'auto',
    width: '100%',
    maxWidth: '35rem',
    padding: '0rem 2rem 4rem',
  },
  card: {
    padding: '0rem 1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0rem',
    },
  },
  techTitle: {
    fontSize: '1.6rem',
    fontWeight: '700',
    color: '#bb3c2f',
    padding: '2rem 0rem 2.5rem',
  },
  techParagraph: {
    fontSize: '1.4rem',
    color: '#424242',
    margin: '0rem 1rem 4rem',
    [theme.breakpoints.down('xs')]: {
      margin: '0rem 0rem 4rem',
    },
  },
  lastParagraph: {
    fontSize: '1.6rem',
    color: '#333',
    padding: '6rem 1.5rem 0rem',
    '& q': {
      color: '#cd4436',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '6rem 0rem 0rem',
    },
  },
}));

const Nosql = () => {
  const classes = useStyles();
  return (
    <Grid id="nosql" container className={classes.root}>
      <Grid item className={classes.container}>
        <Typography className={classes.title}>
          <FormattedHTMLMessage id="technologies.nosql.h" />
        </Typography>
        <Typography className={classes.paragraph}>
          <FormattedHTMLMessage id="technologies.nosql.content.p1" />
        </Typography>
        <img id="mongodb" src={mongodb} className={classes.img} alt="mongo" />

        <Grid container justify="center">
          {map(
            index => (
              <Grid key={index} item container justify="center" xs={12} md={6} className={classes.card}>
                <Grid item>
                  <Typography className={classes.techTitle}>
                    <FormattedHTMLMessage id={`technologies.nosql.content.h${index}`} />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.techParagraph}>
                    {' '}
                    <FormattedHTMLMessage id={`technologies.nosql.content.p${index}`} />
                  </Typography>
                </Grid>
              </Grid>
            ),
            [2, 3, 4, 5],
          )}
        </Grid>

        <Typography className={classes.lastParagraph}>
          <FormattedHTMLMessage id="technologies.nosql.content.p6" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Nosql;
