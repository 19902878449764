import { useState } from 'react';

export const useDrawer = () => {
  const [isDrawerVisible, setDrawerVisibility] = useState(false);
  const toggleDrawer = () => setDrawerVisibility(!isDrawerVisible);

  return {
    isDrawerVisible,
    toggleDrawer,
  };
};
