import React from 'react';
import { FormattedMessage } from 'react-intl';
import Scroll from 'react-scroll';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TelegramIcon from '@material-ui/icons/Telegram';
import Button from '@material-ui/core/Button';
import Hexagon from '../hexagon';
import bgPattern from '../../assets/images/pattern-dot-dark-red.png';

const useStyles = makeStyles({
  root: {
    backgroundImage: `url(${bgPattern})`,
    backgroundColor: '#cd4436',
    color: '#dcddd8',
  },
  container: {
    maxWidth: '97rem',
    padding: '0rem 1.5rem 2rem',
    margin: 'auto',
    textAlign: 'center',
  },
  icon: {
    margin: '4rem 0 1rem',
    width: '20rem',
    height: '20rem',
    '&svg': {
      color: '#dcddd8',
    },
  },
  title: {
    fontSize: '5rem',
    margin: '0rem auto 3rem',
  },
  paragraph: {
    fontSize: '2rem',
    margin: '2rem auto 3rem',
  },
  communButton: {
    boxSizing: 'border-box',
    width: '19rem',
    borderRadius: '0px',
    border: '1px solid #fff',
    padding: '1.5rem',
    fontSize: '16px',
    '&:hover': {
      textDecoration: 'none',
      color: '#CD4436',
      backgroundColor: '#fff',
      border: '1px solid #CD4436',
    },
  },
  hiringButton: {
    boxSizing: 'border-box',
    width: '19rem',
    color: '#CD4436',
    backgroundColor: '#fff',
    borderRadius: '0px',
    border: '1px solid #fff',
    padding: '1.5rem',
    fontSize: '16px',
    '&:hover': {
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#CD4436',
      border: '1px solid #fff',
    },
  },
  hexagon: {
    margin: '5rem 0rem -3.5rem',
  },
});

const Splash = () => {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:820px)');
  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.container}>
        <TelegramIcon viewBox="3 2 19 19" className={classes.icon} />
        <Typography align="center" className={classes.title}>
          <FormattedMessage id="home.splash.catch" />
        </Typography>
        <Hexagon hexaColor="#bb3c2f" sideLength="7" />
        <Typography align="center" className={classes.paragraph}>
          <FormattedMessage id="home.splash.mission" />
        </Typography>
        <Typography align="center" className={classes.paragraph}>
          <FormattedMessage id="home.splash.who" />
        </Typography>

        <Grid container direction={matches ? 'row' : 'column'} justify="center" spacing={matches ? 1 : 3}>
          <Grid item>
            <Button
              aria-label="contact us"
              href="mailto:contact@redpelicans.com"
              variant="contained"
              color="primary"
              classes={{ root: classes.communButton }}
            >
              <FormattedMessage id="contactus" />
            </Button>
          </Grid>

          <Grid item>
            <Button
              aria-label="hiring"
              component={Scroll.Link}
              to="hiring"
              smooth={true}
              duration={2000}
              variant="contained"
              color="primary"
              classes={{ root: classes.hiringButton }}
            >
              <FormattedMessage id="hiring" />
            </Button>
          </Grid>

          <Grid item>
            <Button
              aria-label="school"
              component={Scroll.Link}
              to="school"
              smooth={true}
              duration={2000}
              variant="contained"
              color="primary"
              classes={{ root: classes.communButton }}
            >
              <FormattedMessage id="school" />
            </Button>
          </Grid>

          <Grid item>
            <Button
              aria-label="portfolio"
              component={Link}
              to="/portfolio"
              variant="contained"
              color="primary"
              classes={{ root: classes.communButton }}
            >
              <FormattedMessage id="menu.portfolio" />
            </Button>
          </Grid>
        </Grid>
        <Box className={classes.hexagon}>
          <Hexagon hexaColor="#dcddd8" sideLength="7" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Splash;
