import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hexagon from '../hexagon';
import bgPattern from '../../assets/images/pattern-dot-light-grey.png';

const useStyles = makeStyles({
  root: ({ color }) => ({
    backgroundImage: `url(${bgPattern})`,
    backgroundColor: color,
    padding: '6rem 0rem',
    textAlign: 'center',
    position: 'relative',
  }),
  rootButton: {
    borderRadius: '0px',
    border: '1px solid #CD4436',
    margin: 'auto',
    padding: '15px 25px',
    fontSize: '16px',
    '&:hover, &:focus': {
      textDecoration: 'none',
      color: '#CD4436',
      backgroundColor: '#fff',
      border: '1px solid #CD4436',
    },
  },
  talkus: {
    fontSize: '2rem',
    color: '#cd4436',
    padding: '0rem 1.5rem 6rem',
    '& q': {
      color: '#424242',
    },
  },
  hexagon: {
    position: 'absolute',
    top: '100%',
    transform: 'translate(-50%, -42%)',
    left: '50%',
  },
});

const Contact = ({ color = '#fff' }) => {
  const classes = useStyles({ color });
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>
        <Typography className={classes.talkus}>
          <FormattedHTMLMessage id="talkus" />
        </Typography>
      </Grid>
      <Grid item>
        <Button
          aria-label="contact us"
          href="mailto:contact@redpelicans.com"
          variant="contained"
          color="primary"
          classes={{ root: classes.rootButton }}
        >
          <FormattedHTMLMessage id="contactus" />
        </Button>
      </Grid>
      <Grid item className={classes.hexagon}>
        <Hexagon hexaColor="#dcddd8" sideLength="7" />
      </Grid>
    </Grid>
  );
};

export default Contact;
