import React from 'react';

import Clients from './clients';
import Footer from '../footer';
import Contact from '../contact';
import Splash from './splash';

const Portfolio = () => (
  <main className="portfolio">
    <Splash />
    <Clients />
    <Contact />
    <Footer />
  </main>
);

export default Portfolio;
