import darkerGreyDotPattern from './assets/images/pattern-dot-darker-grey.png';

export default {
  spacing: 4,
  palette: {
    primary: {
      main: '#CD4436',
    },
    secondary: {
      main: '#dcddd8',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: '10px',
          textSizeAdjust: 'none',
        },
        '::selection': {
          color: '#fff',
          backgroundColor: '#cd4436',
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:hover, &:visited, &:focus': {
          color: '#fff',
        },
      },
    },
    MuiFab: {
      primary: {
        textTransform: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '&:hover, &:visited, &:focus': {
          color: '#fff',
          boxShadow: 'none',
        },
      },
    },
    MuiDrawer: {
      paperAnchorLeft: {
        backgroundColor: '#424242',
        backgroundImage: `url(${darkerGreyDotPattern})`,
      },
    },
    MuiListItem: {
      root: {
        textTransform: 'uppercase',
        WebkitTextFillColor: '#dcddd8',
        '& svg': {
          color: '#dcddd8',
        },
        '&:hover': {
          textDecoration: 'none',
          WebkitTextFillColor: '#fff',
          '& svg': {
            color: '#fff',
          },
        },
      },
    },
    MuiListItemText: {
      primary: {
        color: '#fff',
      },
      inset: {
        paddingLeft: '10px',
      },
    },
    MuiListItemIcon: {
      root: {
        color: '#fff',
        minWidth: 'fit-content',
      },
    },
    MuiChip: {
      root: {
        borderRadius: '.25rem',
        opacity: '.75',
      },
      labelSmall: {
        fontSize: '1.05rem',
        paddingRight: '0rem',
        paddingLeft: '0rem',
      },
      sizeSmall: {
        height: 'fit-content',
        padding: '0.3rem 0.4rem',
      },
    },
    MuiCardActions: {
      root: {
        padding: '16px',
      },
    },
  },
};
