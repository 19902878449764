import React from 'react';
import { map } from 'ramda';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { road, flash, target } from '../../icons';
import bgPattern from '../../assets/images/pattern-dot-light-grey.png';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${bgPattern})`,
    backgroundColor: '#f4f5f3',
    padding: '6rem 0rem',
  },
  container: {
    maxWidth: '97rem',
    padding: '0rem 1.5rem 2rem',
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: '2rem',
    color: '#cd4436',
    paddingBottom: '6rem',
    '& q': {
      color: '#424242',
    },
  },
  contentIcon: {
    display: 'contents',
    '& svg': {
      fill: '#cd4436',
      width: '14rem',
      height: '14rem',
    },
  },
  contentTitle: {
    color: '#cd4436',
    fontSize: '2.2rem',
    padding: '2rem 0rem 2.5rem',
  },
  contentParagraph: {
    fontSize: '1.4rem',
    [theme.breakpoints.up('md')]: {
      margin: '0 3rem 4rem',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0rem 1rem 4rem',
    },
  },
}));

const Consulting1 = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography align="center" className={classes.title}>
            <FormattedHTMLMessage id="home.consulting1.h" />
          </Typography>
        </Grid>
        <Grid item container justify="center">
          {map(
            consultant => (
              <Grid item container direction="column" key={consultant.id} xs={12} md={4}>
                <Grid item>
                  <Icon className={classes.contentIcon}>{consultant.icon}</Icon>
                </Grid>
                <Grid item>
                  <Typography className={classes.contentTitle}>
                    <FormattedMessage id={`home.consulting1.content.h${consultant.id}`} />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.contentParagraph}>
                    <FormattedHTMLMessage id={`home.consulting1.content.p${consultant.id}`} />
                  </Typography>
                </Grid>
              </Grid>
            ),
            [
              { id: 1, icon: road },
              { id: 2, icon: flash },
              { id: 3, icon: target },
            ],
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Consulting1;
