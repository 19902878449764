import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { tree } from '../../icons';

import bgPattern from '../../assets/images/pattern-dot-light-grey.png';

const useStyles = makeStyles({
  root: {
    backgroundImage: `url(${bgPattern})`,
    backgroundColor: '#f4f5f3',
    padding: '6rem 0rem',
  },
  container: {
    maxWidth: '97rem',
    padding: '0rem 1.5rem 2rem',
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: '2rem',
    color: '#cd4436',
    paddingBottom: '6rem',
    '& q': {
      color: '#424242',
    },
  },
  icon: {
    display: 'inline',
    '& span , svg': {
      fill: '#cd4436',
      width: '16rem',
      height: '16rem',
    },
  },
  iconTitle: {
    color: '#cd4436',
    fontSize: '2.2rem',
    padding: '2rem 0rem 2.5rem',
  },
  iconParagraph: {
    fontSize: '1.4rem',
    marginBottom: '4rem',
  },
  hiringButton: {
    color: '#fff',
    backgroundColor: '#CD4436',
    borderRadius: '0px',
    border: '1px solid #CD4436',
    padding: '15px 25px',
    fontSize: '16px',
    '&:hover, &:focus': {
      textDecoration: 'none',
      color: '#CD4436',
      backgroundColor: '#fff',
      border: '1px solid #CD4436',
    },
  },
});

export const Hiring = () => {
  const classes = useStyles();
  return (
    <Box id="hiring" className={classes.root}>
      <Grid container direction="column" className={classes.container}>
        <Grid item>
          <Typography className={classes.title}>
            <FormattedHTMLMessage id="home.hiring.h" />
          </Typography>
        </Grid>
        <Grid item>
          <Icon className={classes.icon}>{tree}</Icon>
        </Grid>
        <Grid item>
          <Typography className={classes.iconTitle}>
            <FormattedMessage id="home.hiring.content.h" />
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.iconParagraph}>
            <FormattedHTMLMessage id="home.hiring.content.p" />
          </Typography>
        </Grid>
        <Grid item>
          <Button aria-label="apply now" href="mailto:contact@redpelicans.com" className={classes.hiringButton}>
            <FormattedMessage id="applynow" />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hiring;
