import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Nav from './nav';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles({
  appBar: {
    backgroundColor: '#CD4436',
    color: '#fff',
    paddingTop: '5rem',
    zIndex: 3,
  },
});

const HideOnScroll = ({ scrollHeight, children }) => {
  const trigger = useScrollTrigger({
    threshold: scrollHeight,
    disableHysteresis: true,
  });
  return (
    <Slide appear={false} direction="down" in={trigger}>
      {children}
    </Slide>
  );
};

const HeadroomRP = ({ scrollHeight }) => {
  const classes = useStyles();
  return (
    <HideOnScroll scrollHeight={scrollHeight}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar variant="dense">
          <Nav headroom />
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default HeadroomRP;
