import React from 'react';
import { reduce } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  hexagonContainer: {
    position: 'relative',
  },
});

const toRadians = angle => angle * (Math.PI / 180);

const svgWidth = (sideLength, strokeWidth) => sideLength * 2 + strokeWidth * 2 - 1;

const svgHeight = (sideLength, strokeWidth) =>
  parseInt(sideLength) * (Math.sin(toRadians(60)) * 2 + 0.5) + strokeWidth * 2 - 1;

const Hexagon = ({
  sideLength = '110',
  hexaColor = '#cd4436',
  strokeWidth = '4',
  strokeColor,
  opacity = 1,
  children,
}) => {
  const classes = useStyles();
  const side = parseInt(sideLength);
  const altitude = side * Math.sin(toRadians(60));
  const xStart = side + parseInt(strokeWidth) - 1;
  const yStart = parseInt(strokeWidth) - 1;

  const pointA = {
    x: xStart,
    y: yStart,
  };

  const pointB = {
    x: xStart + side,
    y: yStart + side / 2,
  };

  const pointC = {
    x: xStart + side,
    y: yStart + altitude * 2,
  };

  const pointD = {
    x: xStart,
    y: yStart + altitude * 2 + side / 2,
  };

  const pointE = {
    x: xStart - side,
    y: yStart + altitude * 2,
  };

  const pointF = {
    x: xStart - side,
    y: yStart + side / 2,
  };

  const points = reduce((acc, point) => acc + `${point.x},${point.y} `, '', [
    pointA,
    pointB,
    pointC,
    pointD,
    pointE,
    pointF,
  ]);

  return (
    <svg
      width={svgWidth(sideLength, strokeWidth)}
      height={svgHeight(sideLength, strokeWidth)}
      className={classes.hexagonContainer}
    >
      <polygon
        points={points}
        fill={hexaColor}
        stroke={strokeColor || hexaColor}
        strokeWidth={strokeWidth}
        fillOpacity={opacity}
      />

      <foreignObject x="0" y="0" width="100%" height="100%">
        {children}
      </foreignObject>
    </svg>
  );
};

export default Hexagon;
