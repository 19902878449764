import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import darkRedDotPattern from '../../assets/images/pattern-dot-dark-red.png';
import Hexagon from '../hexagon';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#CD4436',
    backgroundImage: `url(${darkRedDotPattern})`,
    position: 'relative',
  },
  title: {
    fontSize: '5rem',
    margin: '6rem auto',
  },
  hexagon: {
    position: 'absolute',
    top: '100%',
    transform: 'translate(-50%, -42%)',
    left: '50%',
  },
});

const Splash = () => {
  const classes = useStyles();
  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item>
        <Typography color="secondary" className={classes.title}>
          <FormattedHTMLMessage id="portfolio.splash.h" />
        </Typography>
      </Grid>
      <Grid item className={classes.hexagon}>
        <Hexagon hexaColor="#dcddd8" sideLength="7" />
      </Grid>
    </Grid>
  );
};

export default Splash;
