import React from 'react';
import { map } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedHTMLMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import SpeedIcon from '@material-ui/icons/Speed';
import DnsIcon from '@material-ui/icons/Dns';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '6rem 0rem',
  },
  container: {
    maxWidth: '97rem',
    padding: '0rem 1.5rem 2rem',
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: '4rem',
    color: '#cd4436',
    marginBottom: '2rem',
    padding: '0rem 1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0rem',
    },
  },
  icon: {
    fontSize: '16rem',
    color: '#cd4436',
  },
  paragraph: {
    fontSize: '1.6rem',
    color: '#333',
    padding: '0rem 1.5rem 4rem',
    '& q': {
      color: '#cd4436',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0rem 0rem 4rem',
    },
  },
  card: {
    padding: '0rem 1.5rem',
  },
  techTitle: {
    fontSize: '2.2rem',
    color: '#cd4436',
    padding: '2rem 0rem 2.5rem',
  },
  techParagraph: {
    fontSize: '1.4rem',
    color: '#424242',
    margin: '0rem 3rem 4rem',
    [theme.breakpoints.down('sm')]: {
      margin: '0rem 0rem 4rem',
    },
  },
  lastParagraph: {
    fontSize: '1.6rem',
    color: '#333',
    padding: '6rem 1.5rem 0rem',
    '& q': {
      color: '#cd4436',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '6rem 0rem 0rem',
    },
  },
}));

const JSTECH = [
  {
    id: 1,
    icon: SpeedIcon,
  },
  {
    id: 2,
    icon: DnsIcon,
  },
];

const Javascript2 = () => {
  const classes = useStyles();
  return (
    <Grid id="full-stack-javascript" container className={classes.root}>
      <Grid item className={classes.container}>
        <Typography className={classes.title}>
          <FormattedHTMLMessage id="technologies.javascript2.h1" />
        </Typography>

        <Typography className={classes.paragraph}>
          <FormattedHTMLMessage id="technologies.javascript2.p1" />
        </Typography>

        <Grid container>
          {map(
            jstech => (
              <Grid item container direction="column" key={jstech.id} sm={6}>
                <Grid item>
                  <Icon component={jstech.icon} className={classes.icon} />
                </Grid>
                <Grid item>
                  <Typography className={classes.techTitle}>
                    <FormattedHTMLMessage id={`technologies.javascript2.content.h${jstech.id}`} />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.techParagraph}>
                    <FormattedHTMLMessage id={`technologies.javascript2.content.p${jstech.id}`} />
                  </Typography>
                </Grid>
              </Grid>
            ),
            JSTECH,
          )}
        </Grid>

        <Typography className={classes.lastParagraph}>
          <FormattedHTMLMessage id="technologies.javascript2.p2" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Javascript2;
