import React from 'react';
import { map, sortBy, prop } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Client from './client';
import bgPattern from '../../assets/images/pattern-dot-grey.png';
import clients from '../../clients.json';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${bgPattern})`,
    backgroundColor: '#f4f5f3',
    padding: '6rem 0rem',
  },
  clientContainer: {
    maxWidth: '97rem',
    padding: '0rem 1.5rem 2rem',
    margin: 'auto',
  },
  spacing: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      width: '100%',
    },
  },
}));

const Clients = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container justify="center" className={classes.clientContainer}>
        {map(
          client => (
            <Grid item key={client.name} className={classes.spacing}>
              <Client name={client.name} content={client.content} tags={client.tags} link={client.link} />
            </Grid>
          ),
          sortBy(prop('id'), clients),
        )}
      </Grid>
    </Box>
  );
};

export default Clients;
