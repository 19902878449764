import React, { useRef, useEffect } from 'react';
import { tail } from 'ramda';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import Footer from '../footer';
import Contact from '../contact';
import Splash from './splash';
import HeadroomRP from './headroom';
import Javascript1 from './javascript1';
import Javascript2 from './javascript2';
import Datavisualization from './datavisualization';
import Frontend from './frontend';
import Backend from './backend';
import Nosql from './nosql';
import { useWindowHeight, useScrollHeight } from './hooks';

const Technologies = () => {
  const { scrollHeight, setScrollHeight } = useScrollHeight();
  const windowHeight = useWindowHeight();
  const ref = useRef();
  const { hash } = useLocation();
  const target = tail(hash);

  useEffect(() => {
    setScrollHeight(ref.current.scrollHeight);
    if (target) {
      scroller.scrollTo(target, {
        spy: true,
        smooth: true,
        duration: 2000,
        offset: -115,
      });
    }
  }, [windowHeight, setScrollHeight, target]);

  return (
    <main className="technologies">
      <HeadroomRP scrollHeight={scrollHeight} />
      <Splash splashRef={ref} />
      <Javascript1 />
      <Javascript2 />
      <Datavisualization />
      <Frontend />
      <Backend />
      <Nosql />
      <Contact color="#f4f5f3" />
      <Footer />
    </main>
  );
};

export default Technologies;
